export enum EDailyCasesNotificationTypes {
  VK = 'vk',
  TELEGRAM = 'telegram',
  EMAIL = 'email',
  STEAM = 'steam',
}

export enum EDailyCasesButtonPlaces {
  FREE_CASE = 'Free Case',
  FREE_CASES_PAGE = 'Free Cases Page',
  GET_EXP_POPUP = 'Get Exp Popup',
}
