import type { IDailyCaseItem } from '~/repository/modules/cases/cases.types';
import type { ICasesItem } from '~/features/mainPage/Types/cases/client.types';
import { Colors } from '~/constants/colors.constants';

const DEFAULT_COLOR = '#737785';

const labelColorByLevel = new Map([
  [[1, 4], Colors.LVL.LVL_1_4],
  [[5, 9], Colors.LVL.LVL_5_9],
  [[10, 14], Colors.LVL.LVL_10_14],
  [[15, 19], Colors.LVL.LVL_15_19],
  [[20, 29], Colors.LVL.LVL_20_29],
  [[30, 39], Colors.LVL.LVL_30_39],
  [[40, 49], Colors.LVL.LVL_40_49],
  [[50, 59], Colors.LVL.LVL_50_59],
  [[60, 69], Colors.LVL.LVL_60_69],
  [[70, 79], Colors.LVL.LVL_70_79],
  [[80, 89], Colors.LVL.LVL_80_89],
  [[90, 99], Colors.LVL.LVL_90_99],
  [[100, 109], Colors.LVL.LVL_100_109],
  [[110, 119], Colors.LVL.LVL_110_119],
  [[120, 129], Colors.LVL.LVL_120_129],
  [[130, 139], Colors.LVL.LVL_130_139],
  [[140, 149], Colors.LVL.LVL_140_149],
  [[150, 159], Colors.LVL.LVL_150_159],
  [[160, 169], Colors.LVL.LVL_160_169],
  [[170, 179], Colors.LVL.LVL_170_179],
  [[180, 189], Colors.LVL.LVL_180_189],
  [[190, 199], Colors.LVL.LVL_190_199],
  [[200, 200], Colors.LVL.LVL_200],
]);

const checkLevelIncludes = ([start, end]: number[], level: number): boolean => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i).includes(level);
};

const getColorByLevel = (level: number): string => {
  for (const levels of labelColorByLevel.keys()) {
    const isLevelIncludes = checkLevelIncludes(levels, level);
    if (isLevelIncludes) {
      const colorsArray = labelColorByLevel.get(levels);
      return Number(colorsArray?.length) > 1
        ? `linear-gradient(90deg, ${colorsArray?.join(', ')})`
        : colorsArray?.[0] || DEFAULT_COLOR;
    }
  }
  return DEFAULT_COLOR;
};

export const normalizeObject = (cases: IDailyCaseItem[]): ICasesItem[] => {
  const {
    $i18n: { t },
  } = useNuxtApp();
  return cases.map((caseItem) => ({
    ...caseItem,
    image: caseItem.img,
    labelData: {
      background: getColorByLevel(caseItem.level),
      color: '#ffffff',
      text: t('cases.available-up-level', { level: caseItem.level }),
    },
    link: caseItem.name,
    lock: caseItem.available,
    name: `LEVEL ${caseItem.level}`,
    priceData: {},
  }));
};
