import type { EDailyCasesButtonPlaces } from '~/repository/extensions/amplitude/amplitude.types';
import { EDailyCasesNotificationTypes } from '~/repository/extensions/amplitude/amplitude.types';

export enum EDailyCasesEvents {
  INFO_POPUP_CLICKED = 'Free Cases Page - Info Popup Clicked',
  LEVEL_GENERATED = 'Free Cases Page - Level Generated',
  OPEN_FREE_CLICKED = 'Free Cases Page - Open Free Clicked',
  SUBSCRIBE_CLICKED = 'Free Cases Page - Subscribe Clicked',
  USER_SUBSCRIBED = 'Free Cases Page - User Subscribed',
  NEXT_CASE_CLICKED = 'Free Case - Next Case Clicked',
  TIME_LOCK_BUTTON_CLICKED = 'Case - Time Lock Button Clicked',
  LEVEL_LOCK_BUTTON_CLICKED = 'Case - Level Lock Button Clicked',
  FREE_CASE_TO_CASES_CLICKED = 'Free Case - To Cases Clicked',
  PAYMENT_OPENED = 'Payment - Payment Opened',
  FREE_CASES_SECRET_SHOP_CLICKED = 'Free Case - To Secret Shop Clicked',
  GET_EXP_FASTER_CLICKED = 'Free Case - Get Exp Faster Clicked',
  POPUP_TO_CASES_CLICKED = 'Get Exp Popup - To Cases Clicked',
  POPUP_SECRET_SHOP_CLICKED = 'Get Exp Popup - To Secret Shop Clicked',
  CONDITION_LOCK_BUTTON_CLICKED = 'Free Case - Condition Lock Button Clicked',
  TRIGGER_POPUP_SHOWN = 'Trigger - Pop-up Shown',
}

type TNotificationTypeLabel = 'VK' | 'Telegram' | 'Email' | 'Steam';

export const NotificationLabelByType: Record<EDailyCasesNotificationTypes, TNotificationTypeLabel> = {
  [EDailyCasesNotificationTypes.VK]: 'VK',
  [EDailyCasesNotificationTypes.TELEGRAM]: 'Telegram',
  [EDailyCasesNotificationTypes.EMAIL]: 'Email',
  [EDailyCasesNotificationTypes.STEAM]: 'Steam',
};

interface IBaseButtonPlaceEvent {
  'Button Place': EDailyCasesButtonPlaces;
}

interface IBaseCaseInfoEvent {
  'Case ID': number;
  'Case Name': string;
}

// eslint-disable-next-line
export interface IInfoPopupClickEvent extends IBaseButtonPlaceEvent {}

// eslint-disable-next-line
export interface IFreeCasesLevelGeneratedEvent extends IBaseButtonPlaceEvent {}

// eslint-disable-next-line
export interface IFreeCasesSubscribeClickedEvent extends IBaseButtonPlaceEvent {}

// eslint-disable-next-line
export interface IPaymentOpenedEvent extends IBaseButtonPlaceEvent {}

export interface IUserSubscribedEvent {
  'Notification Type': TNotificationTypeLabel;
  'Is Enabled': boolean;
}

export interface ITimeLockButtonClickedEvent extends IBaseCaseInfoEvent {
  'Case Type': string;
  'Time In Min': number;
}

export interface ILevelLockButtonClickedEvent extends IBaseCaseInfoEvent {
  'Case Type': string;
  'Required Level': number;
}

export interface IConditionLockEvent extends IBaseCaseInfoEvent {
  'Required Sum': number;
}
