// Хук для управления процессом генерации уровней и вывода ошибки
import { useAlertStore } from '~/store/alert/alert.store';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';

export function useLevelGeneration() {
  const alertStore = useAlertStore();
  const dailyCasesStore = useDailyCasesStore();

  // Обработчик клика для генерации уровня
  const handlerGenerateLevelClick = async () => {
    const result = await dailyCasesStore.generateLevel();
    if (result) return;

    alertStore.showError({
      message: 'Во время генерации произошла ошибка',
      title: 'Генерация уровня',
    });
  };

  return {
    alertStore,
    handlerGenerateLevelClick,
  };
}
