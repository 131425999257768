import {
  EDailyCasesEvents,
  type IConditionLockEvent,
  type IFreeCasesLevelGeneratedEvent,
  type IFreeCasesSubscribeClickedEvent,
  type IInfoPopupClickEvent,
  type ILevelLockButtonClickedEvent,
  type IPaymentOpenedEvent,
  type ITimeLockButtonClickedEvent,
  type IUserSubscribedEvent,
} from '~/repository/amplitude/types/dailyCases';
import type { PROVIDERS } from '~/composables/useAmplitude';

export class DailyCaseEvents {
  static AMPLITUDE_KEY: keyof typeof PROVIDERS = 'DEFAULT';

  static infoPopupClicked(eventProperties: IInfoPopupClickEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.INFO_POPUP_CLICKED,
      eventProperties,
    });
  }

  static levelGenerated(eventProperties: IFreeCasesLevelGeneratedEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.LEVEL_GENERATED,
      eventProperties,
    });
  }

  static openFreeClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.OPEN_FREE_CLICKED,
    });
  }

  static subscribeClicked(eventProperties: IFreeCasesSubscribeClickedEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.SUBSCRIBE_CLICKED,
      eventProperties,
    });
  }

  static userSubscribed(eventProperties: IUserSubscribedEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.USER_SUBSCRIBED,
      eventProperties,
    });
  }

  static nextCaseClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.NEXT_CASE_CLICKED,
    });
  }

  static timeLockButtonClicked(eventProperties: ITimeLockButtonClickedEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.TIME_LOCK_BUTTON_CLICKED,
      eventProperties,
    });
  }

  static levelLockBtnClicked(eventProperties: ILevelLockButtonClickedEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.LEVEL_LOCK_BUTTON_CLICKED,
      eventProperties,
    });
  }

  static freeCaseToCasesClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.FREE_CASE_TO_CASES_CLICKED,
    });
  }

  static freeCasesSecretShopClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.FREE_CASES_SECRET_SHOP_CLICKED,
    });
  }

  static popupToCasesClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.POPUP_TO_CASES_CLICKED,
    });
  }

  static popupSecretShopClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.POPUP_SECRET_SHOP_CLICKED,
    });
  }

  static paymentOpened(eventProperties: IPaymentOpenedEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.PAYMENT_OPENED,
      eventProperties,
    });
  }

  static getExpFasterClicked() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.GET_EXP_FASTER_CLICKED,
    });
  }

  static conditionLockBtnClicked(eventProperties: IConditionLockEvent) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.CONDITION_LOCK_BUTTON_CLICKED,
      eventProperties,
    });
  }

  static triggerPopupShown() {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: EDailyCasesEvents.TRIGGER_POPUP_SHOWN,
    });
  }
}
